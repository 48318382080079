@font-face {
    font-family: "dunbar";
    font-style: normal;
    font-weight: normal;
    src: local("dunbar"), url("./fonts/dunbar.ttf");
    font-display: swap;
}

@font-face {
    font-family: "proxima";
    font-style: normal;
    font-weight: normal;
    src: local("proxima"), url("./fonts/proxima.ttf");
    font-display: swap;
}
